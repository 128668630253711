import React from 'react'
import _ from 'lodash'

import kicks from '../../assets/images/works/Kicks.webp'
import gulbenkian from '../../assets/images/works/Gulbenkian.webp'
import economico from '../../assets/images/works/Economico.webp'
import sonae from '../../assets/images/works/Sonae.webp'
import trafiurbe from '../../assets/images/works/Trafiurbe.webp'
import unitel from '../../assets/images/works/Unitel.webp'

import { WorkBlock } from '../Blocks'

const WorkTwo = ({ lang }) => {
  const isEn = lang === 'en';

  const WorkBlocks = [
    {
      rootProps: { className: 'work-entry row', id: "kicks" },
      image: { rootProps: { className: 'col-6 col-12-medium image' }, image: kicks, loading: 'eager' },
      workGroup: {
        rootProps: { className: 'col-6 col-12-medium' },
        title: {
          type: 'h2',
          text: 'Kicks',
        },
        workTypes: {
          items: isEn ? ['User Experience', 'Strategy', 'Software Development', 'E-commerce', 'Support'] : ['User Experience', 'Estratégia', 'Desenvolvimento', 'E-commerce', 'Suporte'],
        },
        description: {
          text:
            isEn ? 'We designed the new digital experience at Kicks — a fashion sneakers retailer — from scratch, and we partnered up with them to develop the new online store. One of the biggest challenges we faced during the project was designing a digital experience at the same level of excellence they provide in physical stores.' : 'Desenhámos a nova experiência digital da Kicks - um revendedor de ténis da moda - do início ao fim e juntámo-nos a eles para desenvolver a nova loja online. Um dos maiores desafios que enfrentámos durante o projeto foi desenhar uma experiência digital ao mesmo nível de excelência que proporcionam nas lojas físicas.',
        },
      },
    },
    {
      rootProps: { className: 'work-entry row', id: "gulbenkian-foundation" },
      image: { rootProps: { className: 'col-6 col-12-medium image' }, image: gulbenkian, loading: 'lazy' },
      workGroup: {
        rootProps: { className: 'col-6 col-12-medium' },
        title: {
          type: 'h2',
          text: isEn ? 'Calouste Gulbenkian Foundation' : 'Fundação Calouste Gulbenkian',
        },
        workTypes: {
          items: isEn ? ['Software Development', 'WordPress'] : ['Desenvolvimento', 'WordPress'],
        },
        description: {
          text:
            isEn ? 'We helped developing the web portal of the Calouste Gulbenkian Foundation, whose mission is to foster knowledge and raise the community\'s quality of life through art, charity, science, and education. The portal allows the Foundation to manage all programs and grants, as well as give the user the opportunity to buy tickets for exhibitions and concerts, consult the entire schedule, and learn more about the Foundation and its collections.' : 'Contribuímos para o desenvolvimento do portal web da Fundação Calouste Gulbenkian, que tem como missão fomentar o conhecimento e elevar a qualidade de vida da comunidade através da arte, solidariedade, ciência e educação. O portal permite que a Fundação administre todos os programas e bolsas, para além de dar ao utilizador a oportunidade de comprar bilhetes para exposições e espetáculos, consultar toda a programação e saber mais sobre a Fundação e as suas coleções.',
        },
      },
    },
    {
      rootProps: { className: 'work-entry row', id: "jornal-economico" },
      image: { rootProps: { className: 'col-6 col-12-medium image' }, image: economico, loading: 'lazy' },
      workGroup: {
        rootProps: { className: 'col-6 col-12-medium' },
        title: {
          type: 'h2',
          text: 'Jornal Económico',
        },
        workTypes: {
          items: isEn ? ['Software Development', 'WordPress'] : ['Desenvolvimento', 'WordPress'],
        },
        description: {
          text:
            isEn ? "We are the tech partner of Jornal Económico, Portugal's leading economic newspaper, which has more than 5.2 million visits per month. We develop and support all of Jornal Económico's digital platforms, including their website, which was built in WordPress." : 'Somos o parceiro tecnológico do Jornal Económico, o principal jornal de economia em Portugal e que tem mais de 5,2 milhões de visitas por mês. Desenvolvemos e apoiamos todas as plataformas digitais do Jornal Económico, incluindo o site, que foi construído em WordPress.',
        },
      },
    },
    {
      rootProps: { className: 'work-entry row', id: "sonae-sierra" },
      image: { rootProps: { className: 'col-6 col-12-medium image' }, image: sonae, loading: 'lazy' },
      workGroup: {
        rootProps: { className: 'col-6 col-12-medium' },
        title: {
          type: 'h2',
          text: isEn ? "Sonae Sierra Group's Shopping Centers" : 'Centros Comerciais do Grupo Sonae Sierra',
        },
        workTypes: {
          items: isEn ? ['User Experience', 'Strategy', 'Software Development', 'Support'] : ['User Experience', 'Estratégia', 'Desenvolvimento', 'Suporte'],
        },
        description: {
          text:
            isEn ? 'We partnered with Sonae Sierra to develop the digital engagement platform for the more than thirty shopping centers of Sonae Sierra spread all over Europe. The platform has many components, including the website, mobile app, promotion management system, dynamic store maps, store management, product management, dynamic schedules, logistics private area, integration with social networks, newsletters, API, video wall management system,  touchscreens, and marketing automation.' : 'Desenvolvemos uma plataforma de envolvimento digital para os mais de trinta centros comerciais da Sonae Sierra espalhados por toda a Europa. A plataforma tem vários componentes, incluindo um site rápido, aplicação móvel, sistema de gestão de promoções, mapas dinâmicos de lojas, gestão de lojas, gestão de produtos, agendamentos dinâmicos, área reservada de logística, integração com redes sociais, newsletters, API, sistema de gestão de video wall, touchscreens e automatização de marketing.',
        },
      },
    },
    {
      rootProps: { className: 'work-entry row', id: "trafiurbe" },
      image: { rootProps: { className: 'col-6 col-12-medium image' }, image: trafiurbe, loading: 'lazy' },
      workGroup: {
        rootProps: { className: 'col-6 col-12-medium' },
        title: {
          type: 'h2',
          text: 'Trafiurbe',
        },
        workTypes: {
          items: isEn ? ['Discovery', 'User Experience', 'Software Development', 'Support'] : ['Descoberta','User Experience', 'Desenvolvimento', 'Suporte'],
        },
        description: {
          text:
            isEn ? "We helped Trafiurbe - a road signaling company that aims to make roads safer - to create a Computerized Maintenance Management System (CMMS). The solution we developed allows Trafiurbe to centralize its maintenance information and optimize the processes of maintenance operations, and it's a key part of their operations." : "Ajudámos a Trafiurbe - uma empresa de sinalização rodoviária que visa tornar as estradas mais seguras - a criar um Sistema Informatizado de Gestão de Manutenção (CMMS). A solução que desenvolvemos permite à Trafiurbe centralizar as suas informações de manutenção e otimizar os processos das operações de manutenção, sendo uma parte fundamental das suas operações.",
        },
      },
    },
    {
      rootProps: { className: 'work-entry row' },
      image: { rootProps: { className: 'col-6 col-12-medium image' }, image: unitel, loading: 'lazy' },
      workGroup: {
        rootProps: { className: 'col-6 col-12-medium' },
        title: {
          type: 'h2',
          text: 'Unitel',
        },
        workTypes: {
          items: isEn ? ['Discovery', 'User Experience', 'Software Development', 'Support'] : ['Descoberta','User Experience', 'Desenvolvimento', 'Suporte'],
        },
        description: {
          text:
            isEn ? "We developed SDM Stats Tool, a web application for consulting information and statistics about the subscribers of a telecommunications operator in a centralized manner and with very fast response times. For this purpose, the application collects, pre-processes, and processes information from four different systems. With the SDM Stats Tool, the user can consult different information about subscribers, their services, and service classes, as well as a set of statistics about the operator's subscribers." : 'Desenvolvemos a SDM Stats Tool, uma aplicação web para a consulta de informações e estatísticas sobre os assinantes de uma operadora de telecomunicações de forma centralizada e com tempos de resposta muito rápidos. Para o efeito, a aplicação reúne, pré-processa e processa informações de quatro sistemas diferentes. Com a SDM Stats Tool, o utilizador pode consultar diversas informações sobre assinantes, os seus serviços e classes de serviços, além de um conjunto de estatísticas sobre os assinantes da operadora.',
        },
      },
    },
  ];

  return (
    <section id="work-two" className="content container">
      <div className="row">
        {_.map(WorkBlocks, (block, i) => (
          <React.Fragment key={`work-block-${i}`}>
            <WorkBlock rev={i % 2} {...block} texts={{ less: isEn ? 'Know less' : 'Ver menos', more: isEn ? 'Know more' : 'Ver mais' }} />
          </React.Fragment>
        ))}
      </div>
    </section>
  )
}
export default WorkTwo
