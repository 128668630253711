import React from 'react'

import Layout from '../components/layout'
import WorkOne from '../components/work/WorkOne'
import WorkThree from '../components/work/WorkThree'
import WorkTwo from '../components/work/WorkTwo'

const Work = props => (
  <Layout
    lang='pt'
	title="Trabalho"
	meta={{description: 'Trabalhamos com grandes empresas e startups que estão ansiosas por mudar o mundo.'}}
    rootProps={{ className: 'page work bg-blue' }}
    headerProps={{ isBlue: true }}
  >
    <WorkOne lang="pt" />
    <WorkTwo lang="pt" />
    <WorkThree lang="pt" />
  </Layout>
)

export default Work
