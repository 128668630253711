import React from 'react'
import _ from 'lodash'

import TextGroup, { Image } from '../Texts'

import c_logo1 from '../../assets/images/company-logos/hp-white.svg'
import c_logo2 from '../../assets/images/company-logos/nos-white.svg'
import c_logo3 from '../../assets/images/company-logos/ericsson-white.svg'
import c_logo4 from '../../assets/images/company-logos/sonae-white.svg'
import c_logo5 from '../../assets/images/company-logos/sportzone-white.svg'
import c_logo6 from '../../assets/images/company-logos/worten-white.svg'
import c_logo7 from '../../assets/images/company-logos/ageas-white.svg'
import c_logo8 from '../../assets/images/company-logos/bynd-white.svg'

const companies = [
  {
    image: c_logo1,
    title: 'HP',
  },
  {
    image: c_logo2,
    title: 'NOS',
  },
  {
    image: c_logo3,
    title: 'ERICSSON',
  },
  {
    image: c_logo4,
    title: 'SONAE',
  },
  {
    image: c_logo5,
    title: 'SPORTZONE',
  },
  {
    image: c_logo6,
    title: 'WORTEN',
  },
  {
    image: c_logo7,
    title: 'Ageas',
  },
  {
    image: c_logo8,
    title: 'bynd',
  },
]

const WorkThree = ({ lang }) => {
  const isEn = lang === 'en';
  return (
    <section id="work-three" className="bg-blue">
      <div className="container content">
        <TextGroup
          title={{
            type: 'h2',
            text: isEn ? 'Other clients.' : 'Outros Clientes',
          }}
        />
        <div className="company-images">
          {_.map(companies, (company, i) => (
            <React.Fragment key={`${company.title}-${i}`}>
              <Image {...company} />
            </React.Fragment>
          ))}
        </div>
      </div>
    </section>
  )
}
export default WorkThree
