import React from 'react'

import TextGroup from '../Texts'


const WorkOne = ({ lang }) => {
  const isEn = lang === 'en';
  return (
    <section id="work-one" className="content container">
      <TextGroup
        rootProps={{ className: 'align-center' }}
        title={{
          type: 'h1',
          text: isEn ? 'We Work With Great Clients.' : 'Trabalhamos Com Grandes Clientes',
        }}
        description={{
          text:
            isEn ? 'From big corporations to startups that are eager\nto change the world.' : 'De grandes empresas a startups que estão ansiosas por mudar o mundo.',
        }}
      />
    </section>
  )
}
export default WorkOne
